import { FC } from 'react';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { cx, LinariaClassName } from '@linaria/core';

import BottomLogo from './BottomLogo';
import * as styles from './styles';
import { bottomLogo } from './styles';

interface Props {
  className?: LinariaClassName;
  wrapperClassName?: LinariaClassName;
}

const Footer: FC<Props> = ({ className, wrapperClassName }) => {
  const { t } = useTranslation('common');

  return (
    <div className={cx(wrapperClassName, styles.footer)}>
      <div
        {...{
          className: cx(className, styles.container),
        }}
      >
        <p className={styles.textGroupWrapper}>
          <span className={styles.textItem}>{t('copyright')}</span>&nbsp;
          <span className={styles.textItem}>{t('rightsReserved')}</span>
        </p>
        <BottomLogo className={bottomLogo} />
        <p className={styles.textGroupWrapper}>
          <Link href={'/docs/privacy-policy'}>
            <span className={cx(styles.textItem, styles.link)}>
              {t('privacyPolicy')}
            </span>
          </Link>
          <span>&nbsp;•&nbsp;</span>
          <Link
            href={'/docs/terms-and-conditions'}
            className={cx(styles.textItem)}
          >
            <span className={cx(styles.textItem, styles.link)}>
              {t('terms')}
            </span>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Footer;
